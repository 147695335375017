import _minDocument from "min-document";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var topLevel = typeof _global !== "undefined" ? _global : typeof window !== "undefined" ? window : {};
var minDoc = _minDocument;
var doccy;

if (typeof document !== "undefined") {
  doccy = document;
} else {
  doccy = topLevel["__GLOBAL_DOCUMENT_CACHE@4"];

  if (!doccy) {
    doccy = topLevel["__GLOBAL_DOCUMENT_CACHE@4"] = minDoc;
  }
}

exports = doccy;
export default exports;